<template>
<!-- 月自评表监管 -->
  <div class="container">
    <!-- 第一个卡片 -->
    <el-card v-if="current == 1">
      <!-- 搜索区域 -->
      <div class="search">
        <el-row>
          <el-form ref="searchForm" :model="searchForm" label-width="80px">
            <el-col :span="4">
              <el-form-item label="项目名称 : " prop="name">
                <el-input v-model="searchForm.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="工程阶段 : " prop="types">
                <el-select v-model="searchForm.types" placeholder="请选择">
                  <el-option
                    v-for="item in projectOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <!-- <el-col :span="4">
              <el-form-item label="区域位置">
                <el-input v-model="searchForm.screen"></el-input>
              </el-form-item>
            </el-col> -->
            <el-col :span="4">
              <el-button
                type="primary"
                @click="searchProject"
                class="searhAllBtn"
                >查询</el-button
              >
              <el-button type="primary" @click="resetProject_Name"
                >重置</el-button
              >
            </el-col>
          </el-form>
        </el-row>
      </div>
      <!-- 表格区域 -->
      <div class="main">
        <el-table
          :data="tableData1"
          style="width: 100%"
          :header-cell-style="headerCellStyle"
          :cell-style="cellStyle"
        >
          <el-table-column
            prop="name"
            label="项目名称"
            width="250"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="corporations"
            label="所属企业"
            width="250"
            align="center"
          >
          </el-table-column>

          <el-table-column prop="engineering" label="工程类型" align="center">
          </el-table-column>
          <el-table-column prop="create_time" label="开工日期" align="center">
          </el-table-column>
          <el-table-column prop="update_time" label="拟竣工日期" align="center">
          </el-table-column>
          <el-table-column prop="angineering" label="当前阶段" align="center">
          </el-table-column>
          <el-table-column
            prop="pj_img_count"
            label="当前阶段照片总计"
            align="center"
          >
          </el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <a @click="getPorjectRisk(scope.row)" style="padding-right: 10px"
                >查看详情</a
              >
              <a @click="evaluationHandle(scope.row)" style="color: #39bd65"
                >评价</a
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="footer">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <el-dialog
        title="评分"
        :visible.sync="scoreDialogVisible"
        width="30%"
        @close="scoreHandleClose"
      >
        <el-form ref="scoreFormData" :model="scoreFormData" label-width="80px">
          <el-form-item label="形象进度 : " prop="image_progress">
            <el-input-number
              v-model="scoreFormData.image_progress"
              :precision="2"
              :step="0.1"
              :min="0"
              :max="100"
            ></el-input-number>
            %
          </el-form-item>
          <el-form-item label="得分 : " prop="total_score">
            <el-input-number
              v-model="scoreFormData.total_score"
              :precision="2"
              :step="0.1"
              :min="0"
              :max="100"
            ></el-input-number>
            分
          </el-form-item>
          <el-form-item label="选择图片 : ">
            <el-upload
              class="upload-demo"
              action="http://139.9.0.126:8088/api/document/crt/"
              :on-success="successHandle"
              :before-remove="beforeRemove"
              multiple
              ref="upload"
              :limit="1"
              :headers="token"
              :on-exceed="handleExceed"
              :file-list="fileList"
              accept=".jpg,.png"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                只能上传jpg/png文件，且不超过2M
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="scoreDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="scoreHandle">确 定</el-button>
        </span>
      </el-dialog>

      <!-- <div class="out"></div> -->
    </el-card>
    <!-- 第二个卡片 -->
    <el-card v-else-if="current == 2">
      <div class="projectInfo">
        <div class="projectTitle">
          <div>项目基本信息</div>

          <el-button type="primary" @click="current = 1">返回上级</el-button>
        </div>
        <div>
          项目名称: <span> {{ projectInfo.name }}</span>
        </div>

        <div>
          工程类型: <span>{{ projectInfo.engineering }}</span>
        </div>
        <div>
          当前阶段: <span> {{ projectInfo.angineering }}</span>
        </div>
        <div>
          开工日期: <span>{{ projectInfo.create_time }}</span>
        </div>
        <div>
          拟竣工日期: <span>{{ projectInfo.update_time }}</span>
        </div>
      </div>
      <div>
        <el-table :data="scoreTableData" style="width: 100%"    :header-cell-style="headerCellStyle"
          :cell-style="cellStyle">
          <el-table-column prop="year" label="年份" >
          </el-table-column>
          <el-table-column prop="month" label="月份" >
          </el-table-column>
          <el-table-column prop="phase" label="所属阶段"> </el-table-column>
          <el-table-column prop="state" label="形象进度%"> </el-table-column>
          <el-table-column prop="total_score" label="评分总计/分"> </el-table-column>
          <el-table-column prop="uploader" label="上传人"> </el-table-column>
          <el-table-column prop="up_time" label="上传更新时间"> </el-table-column>
          <el-table-column prop="doc_url" label="操作"> 
            <template slot-scope="scope">
              <div @click="previewPicture(scope.row)">查看</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-dialog
       
        :visible.sync="showDialogVisible"
        width="50%"
      >
      <div>
        <el-image style="width: 900px; height: 500px" :src="url"></el-image>
      </div>
       
        <!-- <div>部位:{{pictureInfo.construction_request}}</div>
         <div>相关要求:{{pictureInfo.requirement}}</div> -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="showDialogVisible = false">取 消</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { getProjectRiskNum } from "../../../request/risk";
import { addScore, getScore } from "../../../request/production";
import { getProjectInfo } from "../../../request/project";
export default {
  data() {
    return {
      project_Name: "", //第一个卡片 搜索项目名称
      //   第一个卡片表格数据
      tableData1: [],
      // 第二个卡片表格数据

      total: 10, // 表格分页总数
      page: 1,
      pageSize: 10,

      current: 1, // 第几个卡片
      flag: true, //判断用户权限 系统管理员  政府人员 true    项目管理员  普通用户  false
      activeName: "1", //标签页

      projectId: "", //点击项目详情 记录id
      projectName: "", //项目名称
      companyName: "", //公司名称
      riskInfo: {}, // 第三个卡片数据
      riskNum: {}, //所有项目风险数量
      projectRiskNum: {}, // 选中项目风险数量

      searchForm: {
        name: "",
        types: "",
      }, // 搜索表单
      projectInfo: {}, //指定项目信息
      setps: 1, //步骤条高亮
      setps1: "0项/0张",
      setps2: "0项/0张",
      setps3: "0项/0张",
      screeningTableData: [], //排查要求表格
      showDialogVisible: false, //控制图片详情对话框的显示与隐藏
      pictureInfo: {}, //图片信息对话框
      projectOptions: [
        {
          label: "基础施工阶段",
          value: "基础施工阶段",
        },
        {
          label: "主体施工阶段",
          value: "主体施工阶段",
        },
        {
          label: "装饰装修阶段",
          value: "装饰装修阶段",
        },
      ], //项目阶段下拉框
      scoreDialogVisible: false, //项目评价对话框的显示与隐藏
      scoreFormData: {
        image_progress: "",
        total_score: "",
      }, //项目评分对话框表单
      token: {
        Authorization: localStorage.getItem("TOKEN"),
      },
      fileList: [], // 上传图片数组
      scoreTableData:[],//评价表格
      url:"",//查看图片详情url
      pjtid:'',
    };
  },

  watch: {
    $route() {
      this.pjtid = this.$route.query.id
    },
    pjtid() {
      this.current = 1;
      this.getProjectRisk();
    },
  },

  created() {
    this.getProjectRisk();
  },
  methods: {
    // 根据权限 获取风险统计
    getProjectRisk() {
      this.flag =
        localStorage.getItem("rank") == 0
          ? true
          : false;

      if (this.flag) {
        // 系统管理员  政府账号  显示第一个卡片  获取所有项目
        this.getProjectRiskStatistical();
      } else {
        // 项目管理员  普通用户  显示第二个卡片  获取所属项目所有风险
        // this.current = 2;
        this.projectId = localStorage.getItem("corporations");
        this.getProjectRiskStatistical();
      }
    },

    //  beforeAvatarUpload(file) {
    //     const isJPG = file.type === 'image/jpeg';
    //     const isLt2M = file.size / 1024 / 1024 < 2;

    //     if (!isJPG) {
    //       this.$message.error('上传头像图片只能是 JPG 格式!');
    //     }
    //     if (!isLt2M) {
    //       this.$message.error('上传头像图片大小不能超过 2MB!');
    //     }

    //   },
    successHandle(res) {
      // console.log(res.res);
      // console.log(res);
      this.scoreFormData.doc_url = res.res;
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    // 打开查看图片对话框
    previewPicture(row){
      console.log(row);
      // this.$nextTick(()=>{
      //    this.url = row.doc_url;
      // })
     this.url = row.doc_url;
     this.$nextTick(()=>{
       this.showDialogVisible = true;
     })
      // this.showDialogVisible = true;
      
    },
    // 第一个卡片搜索
    searchProject() {
      this.page = 1
      this.getProjectRiskStatistical();
    },
    // 第一个卡片重置
    resetProject_Name() {
      this.searchForm= {
        name: "",
        types: "",
      }
      this.getProjectRiskStatistical();
    },
    // 获取所有项目风险统计
    async getProjectRiskStatistical() {
      // console.log(this.page);
      let msg = {
        unique_identification: localStorage.getItem("uid"),
        page: this.page,
        size: this.pageSize,
        name: this.searchForm.name,
        phase: this.searchForm.types,
      };
      if (localStorage.getItem('isdealer') == 'true') {
        if (localStorage.getItem('type') == 'pro') {
          msg.pro_id = localStorage.getItem('project') 
        }else if (localStorage.getItem('type') == 'firm') {
          msg.firm_id = localStorage.getItem('project') 
        }
      }
      const res = await getProjectInfo(msg);
      // console.log(res);
      this.tableData1 = res.data.data;
      this.total = res.data.count;
      // if (!this.flag) {
      //   this.getPorjectRisk(res.data[0]);
      // }

      for (let i = 0; i < this.tableData1.length; i++) {
        // 格式化开工时间
        if (this.tableData1[i].create_time) {
          let date1 = new Date(this.tableData1[i].create_time);
          let YY = date1.getFullYear() + "-";
          let MM =
            (date1.getMonth() + 1 < 10
              ? "0" + (date1.getMonth() + 1)
              : date1.getMonth() + 1) + "-";
          let DD =
            date1.getDate() < 10 ? "0" + date1.getDate() : date1.getDate();
          let hh =
            (date1.getHours() < 10
              ? "0" + date1.getHours()
              : date1.getHours()) + ":";
          let mm =
            (date1.getMinutes() < 10
              ? "0" + date1.getMinutes()
              : date1.getMinutes()) + ":";
          let ss =
            date1.getSeconds() < 10
              ? "0" + date1.getSeconds()
              : date1.getSeconds();
          this.tableData1[i].create_time = YY + MM + DD + " " + hh + mm + ss;
        }
        // 格式化拟竣工 时间
        if (this.tableData1[i].update_time) {
          let date2 = new Date(this.tableData1[i].update_time);
          let YY1 = date2.getFullYear() + "-";
          let MM1 =
            (date2.getMonth() + 1 < 10
              ? "0" + (date2.getMonth() + 1)
              : date1.getMonth() + 1) + "-";
          let DD1 =
            date2.getDate() < 10 ? "0" + date2.getDate() : date2.getDate();
          let hh1 =
            (date2.getHours() < 10
              ? "0" + date2.getHours()
              : date2.getHours()) + ":";
          let mm1 =
            (date2.getMinutes() < 10
              ? "0" + date2.getMinutes()
              : date2.getMinutes()) + ":";
          let ss1 =
            date2.getSeconds() < 10
              ? "0" + date2.getSeconds()
              : date2.getSeconds();
          this.tableData1[i].update_time =
            YY1 + MM1 + DD1 + " " + hh1 + mm1 + ss1;
        }
        // 计算项目照片总数
        let step = 0;
        let project_data = this.tableData1[i].project_data;
        for (let j = 0; j < project_data.length; j++) {
          step += project_data[j].cons_images_count;
        }
        this.tableData1[i].cons_images_count = step;
      }
      // 获取项目信息
    },
    // 打开项目评价对话框
    evaluationHandle(row) {
      // console.log(row);
      this.scoreDialogVisible = true;
      this.scoreFormData.pro_id = row.id;
      var date = new Date();
      console.log(date);
      this.scoreFormData.year = date.getFullYear();
      this.scoreFormData.month = date.getMonth() + 1;
      this.scoreFormData.uploader = localStorage.getItem("username");
      // console.log(this.scoreFormData);
    },
    // 对项目进行评价
    async scoreHandle() {
      const res = await addScore(this.scoreFormData);
      // console.log(res);
      if(res.data.code == 200) {
        this.scoreDialogVisible = false;
        this.$message.success("评价成功");
        this.getProjectRiskStatistical()
      }
    },
    // 评价对话框关闭
    scoreHandleClose() {
      this.$refs.scoreFormData.resetFields();
      this.$refs.upload.clearFiles();
      // console.log('ss');
      
    },
    //   表格表头颜色
    headerCellStyle(row, column, rowIndex, columnIndex) {
      //根据报警级别显示颜色
      // return 'background:SlateGray ;color:white'
      return "background:#4c7cfc ;color:white";
    },
    // 表格颜色
    cellStyle(row, column, rowIndex, columnIndex) {
      //根据报警级别显示颜色
      // console.log(row);
      // console.log(row.column);
      if (row.column.label === "重大风险统计") {
        return "color:red";
      } else if (row.column.label === "操作") {
        return "color:#3090f0;cursor:pointer;";
      } else if (
        row.column.label === "风险等级" &&
        row.row.riskrank === "低风险"
      ) {
        return "color:blue";
      } else if (
        row.column.label === "风险等级" &&
        row.row.riskrank === "一般风险"
      ) {
        return "color:skyblue";
      } else if (
        row.column.label === "风险等级" &&
        row.row.riskrank === "较大风险"
      ) {
        return "color:orange";
      } else if (
        row.column.label === "风险等级" &&
        row.row.riskrank === "重大风险"
      ) {
        return "color:red";
      }
    },

    // 切换每页显示几条数据
    handleSizeChange(val) {
      this.pageSize = val;
      // console.log(this.pageSize);
      if (this.current == 1) {
        // 第一个卡片分页获取
        this.getProjectRiskStatistical();
      }
    },
    // 切换页码
    handleCurrentChange(val) {
      this.page = val;
      if (this.current == 1) {
        // 第一个卡片分页获取
        this.getProjectRiskStatistical();
      }
    },
    // 点击项目详情
    async getPorjectRisk(row) {
      // console.log(row);
      this.page = 1;
      this.pageSize = 10;
      this.current = 2;
      this.projectInfo.name = row.name;
      this.projectInfo.create_time = row.create_time;
      this.projectInfo.update_time = row.update_time;
      this.projectInfo.engineering = row.engineering;
      this.projectInfo.angineering = row.angineering;
      let msg = {
        page: this.page,
        size: this.pageSize,
        pro_id: row.id,
      };
      const res = await getScore(msg);
      console.log(res);
      this.scoreTableData = res.data.data;
      this.total = res.data.count;
      console.log(this.scoreTableData);
      for (let i = 0; i < this.scoreTableData.length; i++) {
         this.scoreTableData[i].up_time = this.$moment(this.scoreTableData[i].up_time).format('YYYY-DD-MM HH:mm:ss')
        
      }
      // if (this.projectInfo.angineering == "基础施工阶段") {
      //   this.setps = 1;
      //   this.setps1 =
      //     row.project_data.length + "项/" + row.cons_images_count + "张";
      // } else if (this.projectInfo.angineering == "主体施工阶段") {
      //   this.setps = 2;
      //   this.setps2 =
      //     row.project_data.length + "项/" + row.cons_images_count + "张";
      // } else if (this.projectInfo.angineering == "装饰装修阶段") {
      //   this.setps = 3;
      //   this.setps3 =
      //     row.project_data.length + "项/" + row.cons_images_count + "张";
      // }
      // this.screeningTableData = row.project_data;
    },
    // 获取选定的项目下风险数量统计
    async getProjectRiskNumber() {
      let msg = {
        project_id: this.projectId,
      };
      const res = await getProjectRiskNum(msg);
      // console.log(res);
      this.projectRiskNum = res.data.data;
      i;
    },

    // 搜索某个项目下的风险
    searhProjectBtn() {
      this.page = 1;
      this.pageSize = 10;

      this.getSecondRisk();
    },

    // 从第二个卡片返回第一个卡片
    goProject() {
      this.current = 1;
      this.page = 1;
      this.pageSize = 10;
      this.getProjectRiskStatistical();
    },

    // 第二个卡片重置搜索条件
    resetHandle() {
      this.searchForm.risk_type = "";
      this.searchForm.risk_name = "";
      this.getProjectRiskStatistical();

    },
    // 打开预览图片对话框
    showPicture(row) {
      this.showDialogVisible = true;
      console.log(row);
      this.pictureInfo.construction_request = row.construction_request;
      this.pictureInfo.requirement = row.requirement;
      this.pictureInfo.cons_images = row.cons_images;
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  .statistical {
    width: 100%;
    height: 150px;
    display: flex;
    padding-top: 20px;
    justify-content: space-between;
    //   background-color: pink;
    .left {
      display: flex;
      .riskBg {
        width: 160px;
        height: 105px;
        background-color: skyblue;
        background: url("../../../assets/img/risk1.png") no-repeat;
        padding: 20px 0 0 20px;
        margin-right: 20px;
        border-radius: 5px;

        .riskTitle {
          font-size: 14px;
          color: #fff;
          margin-bottom: 10px;
        }
        .riskNum {
          font-size: 22px;
          color: #fff;
        }
      }
      > :nth-child(2) {
        background: url("../../../assets/img/risk2.png") no-repeat;
      }
      > :nth-child(3) {
        background: url("../../../assets/img/risk2.png") no-repeat;
      }
      > :nth-child(4) {
        background: url("../../../assets/img/risk3.png") no-repeat;
      }
      > :nth-child(5) {
        background: url("../../../assets/img/risk4.png") no-repeat;
      }
    }
    .right {
      padding-top: 30px;
    }
  }
  .projectInfo {
    width: 100%;
    height: 200px;
    border: 1px solid #e8e8e8;

    margin-bottom: 20px;

    div {
      width: 100%;
      height: 30px;
      padding: 0 20px;
      line-height: 30px;
      span {
        padding-left: 10px;
      }
    }
    .projectTitle {
      width: 100%;
      height: 50px;
      color: #4d7cfe;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // padding: 0 20px;
      border-bottom: 1px solid #e8e8e8;
    }
  }
  .projectSetps {
    width: 100%;
    height: 160px;
    padding: 0 20px;
    border: 1px solid #e8e8e8;
    margin-bottom: 20px;
    .setpsTitle {
      width: 100%;
      height: 50px;
      color: #4d7cfe;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // padding: 0 20px;
      border-bottom: 1px solid #e8e8e8;
    }
    .setpsBody {
      padding-top: 20px;
    }
  }
  .search {
    width: 100%;
    height: 80px;
    // background-color: brown;
    .el-col {
      padding: 0 20px;
    }
    .searhBtn {
      margin-left: 20px;
    }
  }
  .main {
    width: 100%;
    // height: 400px;
    // background-color: cornflowerblue;
  }
  .out {
    width: 100%;
    height: 850px;
    background-color: pink;
  }
}
</style>